import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

export default function Footer() {
  return (
    //new Date().getTime()
    <div className={styles.Footer}>
      <div className={styles.content}>
        <div className={styles.copy}>© 2025 ООО «МАСТЕР-КЛАСС»</div>
        <div className={styles.links}>
          <a
            href={"/docs/offer.pdf?version=" + new Date().getTime()}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Оферта
          </a>
          <a
            href={"/docs/privacy_policy.pdf?version=" + new Date().getTime()}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Политика конфеденциальности
          </a>
          <Link to="/about" className={styles.link}>
            О сервисе
          </Link>
        </div>
      </div>
    </div>
  );
}

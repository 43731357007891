import { useState } from "react";
import styles from "./Form.module.css";
import buttons from "./Buttons.module.css";
import Spinner from "Blocks/Spinner/Spinner";
import { INITIAL_DATA } from "../../Api/config";

const InputField = ({
  id,
  label,
  value,
  setValue,
  disabled,
  min = "0",
  max,
  step,
}) => (
  <div className={styles.field}>
    <label htmlFor={id}>{label}</label>
    <input
      type="number"
      id={id}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      min={min}
      max={max}
      step={step}
      required
      disabled={disabled}
    />
  </div>
);

export default function Form({ onSubmit = () => {}, disabled = false }) {
  const [formData, setFormData] = useState(INITIAL_DATA);

  const handleChange = (field) => (value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <div>
      <form className={styles.Form} onSubmit={handleSubmit} disabled={disabled}>
        <InputField
          id="account"
          label="Номер клиента"
          value={formData.account}
          setValue={handleChange("account")}
          disabled={disabled}
        />
        <InputField
          id="order"
          label="Номер заказа"
          value={formData.order}
          setValue={handleChange("order")}
          disabled={disabled}
        />
        <InputField
          id="amount"
          label="Сумма оплаты в тенге"
          value={formData.amount}
          setValue={handleChange("amount")}
          min="10"
          max="490000"
          step="0.01"
          disabled={disabled}
        />

        <div className={styles.footer}>
          <button className={buttons.Button} type="submit" disabled={disabled}>
            {disabled && (
              <Spinner width="20" height="20" stroke="5" color="#fff" />
            )}
            {disabled ? "Проверяем заказ.." : "Подтвердить заказ"}
          </button>
        </div>
      </form>
    </div>
  );
}

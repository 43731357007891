function getRandomDelay(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const delayInSeconds = getRandomDelay(2, 7); // Получаем случайное число от 2 до 5
console.log(`Card payment request timeout — ${delayInSeconds}.`);

export default function fakeCardRequest(callback) {
  setTimeout(callback, delayInSeconds * 1000);
}
